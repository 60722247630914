/**
 * Dropdowns > Shelf
 */

import $ from 'jquery';
import between from 'helpers/between';
import overlay from 'components/overlay';

export default {
	els: {
		dropdownShelf: $('#dropdown_shelf'),
		dropdownList: $('.dropdown-shelf__list', '#dropdown_shelf'),
		dropdownContent: $('.dropdown-shelf__content', '#dropdown_shelf'),
		dropdownTitle: $('.dropdown-shelf__title', '#dropdown_shelf'),
		dropdownSubtitle: $('.dropdown-shelf__subtitle', '#dropdown_shelf'),
		currentDropdown: null
	},

	states: { },

	running: false,

	listener () {
		$(document).on('modal:close', () => {
			this.rebind();
		});
	},

	// Handle click events and logic flow
	init () {
		if (this.windowHasDropdownExperience()) {
			$('html').addClass('has-shelf');
		} else {
			$('html').removeClass('has-shelf');
		}

		$(window).on('resize', () => {
			if (this.windowHasDropdownExperience()) {
				$('html').addClass('has-shelf');
			} else {
				$('html').removeClass('has-shelf');
			}

			if (this.els.dropdownShelf.hasClass('--active')) {
				const height = this.els.dropdownShelf.find('.dropdown-shelf__content').innerHeight();

				this.els.dropdownShelf.find('.dropdown-shelf__list > li:first').css('margin-top', height);
			}
		});

		$('.dropdown__toggle').on('click', (e) => {
			const element = $(e.currentTarget).parent();

			e.stopPropagation();

			import(/* webpackChunkName: "gsap" */ 'gsap').then((gsap) => {
				setTimeout(() => {
					if (this.windowHasDropdownExperience() && this.elementHasDropdownExperience(element)) {
						if (!this.dropdownStatus()) {
							this.prepareDropdown(element, () => {
								this.openDropdown(element);
								this.linkListener();
							});
						} else {
							this.closeDropdown(element);
						}
					}
				}, 200);
			});
		});

		$(document).on('keyup', (e) => {
			if (e.keyCode === 27) {
				this.closeDropdown();
			}
		});

		this.listener();
	},

	unbind (selector) {
		$(selector).find('.dropdown__toggle').off('click');
	},

	rebind ($context = null) {
		let dropdowns;

		if ($context) {
			if (typeof $context === 'string' || $context instanceof String) {
				dropdowns = $($context).find('.dropdown__toggle');
			} else {
				dropdowns = $context.find('.dropdown__toggle');
			}
		} else {
			dropdowns = $('.dropdown__toggle');
		}

		dropdowns.on('click', (e) => {
			const element = $(e.currentTarget).parent();

			e.stopPropagation();

			import(/* webpackChunkName: "gsap" */ 'gsap').then((gsap) => {
				setTimeout(() => {
					if (this.windowHasDropdownExperience() && this.elementHasDropdownExperience(element)) {
						if (!this.dropdownStatus()) {
							this.prepareDropdown(element, () => {
								this.openDropdown(element);
								this.linkListener();
							});
						} else {
							this.closeDropdown(element);
						}
					}
				}, 200);
			});
		});
	},

	linkListener () {
		$('.dropdown-shelf__close').on('click', () => {
			this.closeDropdown();
		});

		$('#dropdown_shelf a').on('click', (e) => {
			const index = $(e.currentTarget).parents('li').index() + 1;

			if (index > 0) {
				e.preventDefault();
				e.stopPropagation();

				const elementToClick = this.els.currentDropdown.find(`li:nth-of-type(${index})`).find('a');

				setTimeout(() => {
					elementToClick.click();

					if (!elementToClick.attr('data-modal') && !elementToClick.attr('data-owner-filter') && !elementToClick.attr('data-vue-bound')) {
						window.location.href = elementToClick.attr('href');
					}

					// It is with a heavy heart that I write the following lines. lolz 😏
					if (elementToClick.attr('data-vue-bound')) {
						document.dispatchEvent(new CustomEvent('handleVueDropdownClicks', { detail: elementToClick.data() }));
					}

					// TODO: move to this as an indicator on ajax links
					// if (!elementToClick.attr('data-ajax')) {
					// 	window.location.href = elementToClick.attr('href');
					// }
				}, 800);
			}

			this.closeDropdown();
		});
	},

	windowHasDropdownExperience () {
		// Where the dropdowns should pop into a shelf.
		const
			tablet = 768,
			phone = 568;

		let show = false;

		// tablet + touch
		if (between($(window).width(), tablet, phone) && Modernizr.touchevents) {
			show = true;
		} else if ($(window).width() <= phone) {
			show = true;
		} else {
			show = false;
		}

		return show;
	},

	elementHasDropdownExperience (clickedEl) {
		// Where the dropdowns should pop into a shelf.
		let shouldPop = true;

		if (clickedEl.hasClass('--nav') || clickedEl.hasClass('tab-nav__item')) {
			shouldPop = false;
		}

		return shouldPop;
	},

	dropdownStatus () {
		return this.els.dropdownShelf.hasClass('--active');
	},

	// prepare the dropdown to open
	prepareDropdown (clickedEl, callback) {
		const
			title = clickedEl.attr('title'),
			subtitle = clickedEl.attr('subtitle');

		let
			state;

		if (this.hasState(title)) {
			state = this.getState(title);
		} else {
			state = this.prepareState(clickedEl.clone(), title, subtitle);
			this.setState(title, state);
		}

		// sets the contents
		$('#dropdown_shelf').html(state.html());

		// Recache everything
		this.els.dropdownShelf = $('#dropdown_shelf');
		this.els.dropdownList = $('.dropdown-shelf__list', '#dropdown_shelf');
		this.els.dropdownContent = $('.dropdown-shelf__content', '#dropdown_shelf');
		this.els.dropdownTitle = $('.dropdown-shelf__title', '#dropdown_shelf');
		this.els.dropdownSubtitle = $('.dropdown-shelf__subtitle', '#dropdown_shelf');

		return callback();
	},

	// check if has state
	hasState (title) {
		return (this.states.hasOwnProperty(title));
	},

	// Build the HTML, return the state
	prepareState (clickedEl, title, subtitle = false) {
		// Used in the context of the $.each so we can work with the current iteration
		const
			state = this.els.dropdownShelf.clone();

		let
			listItem;

		state.find('.dropdown-shelf__title').html(title);

		if (subtitle !== false) {
			state.find('.dropdown-shelf__subtitle').html(subtitle);
			state.find('.dropdown-shelf__content').removeClass('--no_subtitle');
		} else {
			state.find('.dropdown-shelf__content').addClass('--no_subtitle');
		}

		state.find('.dropdown-shelf__list').empty();

		$.each(clickedEl.find('.dropdown__list > *'), function () {
			if (!($(this).hasClass('dropdown__loader'))) {
				listItem = $(this).addClass('dropdown-shelf__item');
				state.find('.dropdown-shelf__list').append(listItem);
			}
		});

		return state;
	},

	// set new state
	setState (title, state) {
		this.states[title] = state;
	},

	// return cached state
	getState (title) {
		return this.states[title];
	},

	// open the dropdown
	openDropdown (clickedEl) {
		const
			height = this.els.dropdownShelf.find('.dropdown-shelf__content').innerHeight();

		overlay.open('shelf', true);

		this.els.currentDropdown = clickedEl;

		this.els.dropdownShelf.addClass('--active');
		this.els.dropdownShelf.find('.dropdown-shelf__list > li:first').css('margin-top', height);

		import(/* webpackChunkName: "gsap" */ 'gsap').then((gsap) => {
			setTimeout(() => {
				gsap.gsap.fromTo(this.els.dropdownContent, { y: '-110%' }, { y: '0%', ease: gsap.Power4.easeInOut, duration: 0.5 });

				setTimeout(() => {
					gsap.gsap.fromTo(this.els.dropdownList, { y: '110%' }, { y: '0%', ease: gsap.Power4.easeInOut, duration: 0.5 });
				}, 150);
			}, 100);
		});
	},

	// close the dropdown
	closeDropdown () {
		if (!this.running) {
			this.running = true;

			import(/* webpackChunkName: "gsap" */ 'gsap').then((gsap) => {
				gsap.gsap.fromTo(this.els.dropdownList,
					{ y: '0%' },
					{ y: '110%', ease: gsap.Power4.easeInOut, duration: 0.5 }
				);

				setTimeout(() => {
					gsap.gsap.fromTo(this.els.dropdownContent,
						{ y: '0%' },
						{ y: '-110%', ease: gsap.Power4.easeInOut, duration: 0.5 }
					);
				}, 150);
			});

			setTimeout(() => {
				this.els.dropdownShelf.removeClass('--active');
				this.running = false;

				overlay.close();
			}, 650);
		}
	}
};
